import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Badge, css, Text, Button } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

import { FormContext } from '@solid-ui-components/ContentForm'
import useForm from '@helpers/useForm'
import { getPricing, setPrice, getKtLogin } from '../../../../../../site/src/service/auth'
import ContentText from '@solid-ui-components/ContentText'
import { ModalContext } from '@solid-ui-components/Modal'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import IndirimTalebi from '../IndirimTalebi/IndirimTalebi'
import KtProgressEdit from '@solid-ui-blocks/Maliyet/KtProgress'

const styles = {
  middleBox: {
    position: `relative`,
    zIndex: 2
  },
  prevPrice: {
    textDecoration: `line-through`,
    opacity: 0.5,
    fontSize: "16px"
  },
  saveBadge: {
    position: `absolute`,
    top: 3,
    right: 3
  }
}

const FiyatBlock = ({ content: { Data } }) => {
  const { setActiveModal } = useContext(ModalContext)

  const [plan, setPlan] = useState(0)
  const [islemMsj, setislemMsj] = useState("")
  // const [ucretsizGosterilsinMi, setucretsizGosterilsinMi] = useState(false)


  const [ozelIndirimVarMi, setozelIndirimVarMi] = useState(false)
  const [ozelIndirimOrani, setozelIndirimOrani] = useState(0)
  const [gercekFiyat, setgercekFiyat] = useState("")
  const [gecerlilik, setgecerlilik] = useState("")

  const [popTalep, setpopTalep] = useState(false)
  const handleTalepPopup = () => {
    setpopTalep(!popMusteri);

  }




  // const [selectedOption, setSelectedOption] = useState("")
  // const [seciliPaket, setSeciliPaket] = useState({})
  //const seciliPaket  ;
  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = "paketler"



  useEffect(() => {
    // setucretsizGosterilsinMi(true);
    // httpServiceToken.post("Locked/UcretsizKullanmisMi", { params: null }).then((res) => {
    //   if (res.DogruMu) {
    //     setucretsizGosterilsinMi(false);
    //   } else {

    //   }
    //   setislemMsj(res.Mesaj);
    // }).catch((err) => {
    //   let a = 1;
    // });

    setozelIndirimVarMi(false);
    setozelIndirimOrani(false);

    httpServiceToken.post("Locked/BanaOzelIndirim", { params: null }).then((res) => {
      if (res.DogruMu) {
        setozelIndirimVarMi(res.Data.IndirimVarMi);
        setozelIndirimOrani(res.Data.IndirimOrani);
        setgercekFiyat(res.Data.GercekFiyat);
        setgecerlilik(res.Data.Gecerlilik)
      } else {

      }
      setislemMsj(res.Mesaj);
    }).catch((err) => {
      let a = 1;
    });


  }, [])



  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })

  }, [success, submitting, setFormValues])

  const handleChange = e => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [name]: value,

      }

    })
    var ind = name.replace("myRadio", "");
    Data[plan].PaketIcerikleri[ind].KrediListesi.forEach(paket => {
      if (paket.Id == value) {
        paket.SeciliMi = true;
      }
      else {
        paket.SeciliMi = false;
      }
    });


    // setSelectedOption(value);
  };


  return (
    <div id="paketler" className="css-vurnku">
      <Container sx={{ textAlign: `left` }}>
        {/* <KtProgressEdit text={60} setVal={range} pgChange={chng}  ></KtProgressEdit> */}
 

        {Data && (
          <Flex sx={{ flexWrap: `wrap`, alignItems: `center`, m: -3 }}>
            {Data[plan].PaketIcerikleri.map(
              ({ Id, Baslik, SatirAciklamalar, KrediListesi, KrediListesiGoruntulensinMi, PaketTipiText }, i) => (
                <Box key={`item-${Id}`} sx={{ flex: [`auto`, 1], p: 3 }}>
                  <Reveal
                    effect={
                      Data[plan].PaketIcerikleri.length === 3
                        ? i === 0
                          ? 'fadeInDeepRight'
                          : i === Data[plan].PaketIcerikleri.length - 1
                            ? 'fadeInDeepLeft'
                            : null
                        : 'fadeInUp'
                    }
                    css={css(
                      i !== 0 && i !== Data[plan].PaketIcerikleri.length - 1 && styles.middleBox
                    )}
                  >

                    <ContentContainer
                      content={{
                        variant: "cards.paper",
                        bg: i % 2 == 1 ? "omegaDarker" : ""
                      }}
                      variant='cards.paper'
                      sx={{ position: `relative` }}
                    >
                      {i == 2 && ozelIndirimVarMi == true && <Reveal effect='fadeInRight' css={css(styles.saveBadge)}>

                        <table className='tbl-indirim'>
                          <tbody>
                            <tr><td className='tbl-indirim-td'> <Text
                              className="fiyat-aciklama" color="red" >Size Özel Tanımlanan İndirimden</Text>
                            </td>
                            </tr>
                            <tr><td> <Text
                              className="fiyat-aciklama" color="red" >Faydalanabilmeniz İçin Kalan {gecerlilik}</Text>
                            </td>
                            </tr>
                            <tr>
                              <td>
                                <Badge variant='tag-yellow' className="fiyat-indirim">
                                  %{ozelIndirimOrani} İndirim
                                </Badge>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Reveal>
                      }


                      <Flex sx={{ alignItems: `center` }}>
                        <Box>
                          <Text
                            key={`lblbaslik-${i}`}
                            variant={"h5"}
                            color={i % 2 == 0 ? "omegaDark" : "omegaLight"}
                            mb='1'
                          >
                            {Baslik}
                          </Text>
                          <Flex
                            sx={{
                              alignItems: `center`,
                              justifyContent: `center`
                            }}
                          >
                            {(
                              <Reveal effect='fadeInDeepRight'>
                                <Text
                                  key={`lblsecili-${i}`}
                                  color={i % 2 == 0 ? "black" : "white"}
                                  variant={"h2"}
                                  mb='0'
                                  mr='2'
                                >
                                  {KrediListesi.find(s => s.SeciliMi == true).Tutar + "₺ "}
                                </Text>
                              </Reveal>
                            )}
                            {i == 2 ? gercekFiyat != "" && <ContentText
                              content={{
                                text: gercekFiyat
                              }
                              }
                              mb='0'
                              mr='2'
                              sx={{
                                transition: `all .4s ease-in`,
                                ...styles.prevPrice
                              }}
                            /> : <></>
                            }
                            <Text
                              key={`lblpaket-${i}`}
                              variant={"h5"}
                              color={"omegaDark"}
                              mb='0'
                              mr='2'
                            >
                              {" / " + KrediListesi[0].PaketGunSuresi + " Gün "}
                            </Text>

                          </Flex>

                          {i == 2 ? <Text
                            color="alphaLight"
                            mb='1'
                          >
                            {"(Yıllık üyelikte aylık maliyet " + (KrediListesi.find(s => s.SeciliMi == true).Tutar / 12).toFixed(2) + " ₺ )"}
                          </Text> : <></>
                          }
                        </Box>
                      </Flex>
                      <Divider
                        space={3}
                        color={'omegaLight'}
                        borderTopWidth='1px'
                      />

                      <Divider space={2} />
                      {SatirAciklamalar.map(
                        ({ Aciklama, Id }, s) => (

                          Aciklama != "." ? <Text
                            className="fiyat-aciklama"
                            key={`lblaciklama-${s}`}
                            // variant={"h5"}
                            color={i % 2 == 0 ? "omegaDark" : "omegaLight"}
                            mb='2'

                          >
                            {Aciklama}
                          </Text> : <Divider key={`lblaciklama-${s}`} space={3} />

                        ))}

                      <Divider space={2} />
                      {
                        KrediListesi.map(
                          ({ BirimFiyat, IndirmeHakki, Tutar, PaketGunSuresi, Id, SeciliMi }, k) => (

                            KrediListesiGoruntulensinMi == true ?
                              <Flex
                                key={`lblaciklama-${k}`}
                                sx={{
                                  alignItems: `start`,
                                  justifyContent: `start`
                                }}
                              >
                                <form name="myForm">
                                  <input type="radio" name={"myRadio" + i} value={Id}
                                    checked={SeciliMi === true}
                                    onChange={handleChange} />
                                </form>
                                {(
                                  <Reveal effect='fadeInDeepRight'>
                                    <Text
                                      key={`lblindr-${i + k}`}
                                      color={i % 2 == 0 ? "black" : "white"}
                                      // variant={"h5"}
                                      mb='3'
                                      mr='3'
                                    >
                                      {IndirmeHakki + " Kredi"}
                                    </Text>
                                  </Reveal>
                                )}

                                <Text
                                  key={`iblbrmfyt-${i + k}`}
                                  // variant={"h5"}
                                  color={"omega"}
                                  mb='0'
                                  mr='3'
                                  className="fiyat-aciklama"
                                >
                                  {"adet " + BirimFiyat.toFixed(2).replace(".", ",") + "₺"}
                                </Text>


                                <Text
                                  key={`lbltutar-${i + k}`}
                                  // variant={"h5"}
                                  color={i % 2 == 0 ? "black" : "white"}

                                >
                                  {Tutar + "₺"}
                                </Text>

                              </Flex> : null
                          ))


                      }

                      <Divider space={3} />
                      {/* {i == 2 && ozelIndirimVarMi == true &&
                        <Flex
                          sx={{
                            alignItems: `center`,
                            justifyContent: `center`
                          }}
                        >
                          <Button type="submit"
                            id={`btnIndirimTalebi-${i}`} index={i}
                            aria-label="satın al"
                            variant="secondary"

                            onClick={async e => {
                              e.preventDefault();

                              const ktLoginMiii = await getKtLogin();
                              if (ktLoginMiii == true) {

                                setpopTalep(true);
                              }
                              else {
                                localStorage.setItem("f", "e");
                                localStorage.setItem("623327ac7dc90a0020f891aa", "4");
                                setActiveModal('authentication');

                              }

                            }}
                          >
                            {"İndirim Talebinde Bulun"}
                          </Button>
                        </Flex>
                      } */}
                      <Divider space={3} />
                      <Flex
                        sx={{
                          alignItems: `center`,
                          justifyContent: `center`
                        }}
                      >

                        <Button type="submit" className="button-group-link level-1 "
                          id={`btnsec-${i}`} index={i}
                          aria-label="satın al"
                          onClick={async e => {
                            e.preventDefault();
                            localStorage.setItem("f", "h");
                            const secilenKredi = KrediListesi.find(s => s.SeciliMi == true);
                            setPrice(secilenKredi.Id, secilenKredi.PaketGunSuresi, secilenKredi.Tutar, secilenKredi.IndirmeHakki, Baslik);

                            const ktLoginMiii = await getKtLogin();
                            if (ktLoginMiii == true) {

                              window.location.assign("/ambalaj-web/guvenli-odeme");
                            }
                            else {
                              localStorage.setItem("f", "e");
                              localStorage.setItem("623327ac7dc90a0020f891aa", "4");
                              setActiveModal('authentication');

                            }

                          }}

                        >
                          {"Satın Al"}
                        </Button>

                      </Flex>
                    </ContentContainer>
                  </Reveal>
                </Box>
              )
            )}
          </Flex>
        )}
        {/* {ucretsizGosterilsinMi ? <Box sx={{ textAlign: `center` }}>

          <Flex sx={{ flexWrap: `wrap`, alignItems: `center`, m: -3 }}>
            <Box sx={{ flex: [`auto`, 1], p: 3 }}>
              <ContentContainer
                content={{
                  variant: "cards.paper",

                }}
                variant='cards.paper'
                sx={{ position: `relative` }}
              >

                <Flex
                  sx={{
                    alignItems: `center`,
                    justifyContent: `center`
                  }}
                >
                  <Flex sx={{ alignItems: `center` }}>
                    <Box>
                      <Flex
                        sx={{
                          alignItems: `center`,
                          justifyContent: `center`
                        }}
                      >  <Text
                        key={`lblbasl`}
                        variant={"h5"}
                        color="omegaDark"
                        mb='1'
                      >
                          <Badge variant='tag-yellow' className="fiyat-indirim">
                            1 AYLIK ÜCRETSİZ KULLANIM PAKETİ
                          </Badge>
                        </Text>
                        <ContentText
                          content={{
                            text: "680,00₺"
                          }

                          }
                          marginLeft="20px"
                          sx={{

                            ...styles.prevPrice
                          }}
                        />
                        <Text
                          key={`lblseci`}
                          color="black"
                          variant={"h2"}
                          mb='0'
                          marginLeft="10px"
                        >
                          {"0,00 ₺"}

                        </Text>


                        <Box>
                          <Button type="submit" className="button-group-link level-1"
                            aria-label="satın al"
                            onClick={async e => {
                              e.preventDefault();
                              // localStorage.setItem("f", "h");
                              // const secilenKredi = KrediListesi.find(s => s.SeciliMi == true);
                              // setPrice(secilenKredi.Id, secilenKredi.PaketGunSuresi, secilenKredi.Tutar, secilenKredi.IndirmeHakki, Baslik);

                              const ktLoginMiii = await getKtLogin();
                              if (ktLoginMiii == true) {


                                httpServiceToken.post("Locked/UcretsizBirAyEkle", { params: null }).then((res) => {
                                  if (res.DogruMu) {

                                  } else {

                                  }

                                  setislemMsj(res.Mesaj);
                                }).catch((err) => {
                                  let a = 1;
                                });


                              }
                              else {

                                localStorage.setItem("623327ac7dc90a0020f891aa", "5");
                                setActiveModal('authentication');
                              }

                            }}

                          >
                            {"Ücretsiz Hediye Paketini Kullan"}
                          </Button>
                          <Text
                            color="beta"
                            mb='1'
                          >
                            {islemMsj}
                          </Text>
                        </Box>
                      </Flex>
                      <Divider
                        space={3}
                        color={'omegaLight'}
                        borderTopWidth='1px'
                      />

                      <Flex
                        sx={{
                          alignItems: `center`,
                          justifyContent: `center`
                        }
                        }>
                        <Box>

                          <Text
                            color="alpha"
                            mb='1'
                          >
                            Her kullanıcıya bir defa tanımlanacaktır
                          </Text>
                          <Text
                            color="alpha"
                            mb='1'
                          >
                            Paket satın alan kullanıcıların lisans sürelerine eklenecektir
                          </Text>

                        </Box>
                        <Box>
                          <Text
                            color="alpha"
                            mb='1'
                          >
                            30 gün boyunca tüm modelleri sınırsız indirme hakkı bulunmaktadır
                          </Text>
                          <Text
                            color="alpha"
                            mb='1'
                          >
                            Kampanya katılımı sınırlı sayıda yapılacaktır
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>

                </Flex>
              </ContentContainer>
            </Box>
          </Flex>
        </Box> : <Box sx={{ textAlign: `center` }}>
        </Box>
        } */}

        {popTalep == true && <IndirimTalebi
          addEventOpen={popTalep}
          handlePopup={handleTalepPopup}
          detayName={"İndirim Talebi"}
          seciliRow={null}


        />
        }

      </Container>
    </div>
  )
}

export default WithDefaultContent(FiyatBlock)
