import React, { useEffect, useContext, useState, useRef } from 'react'
import { Container, Flex, Box, Heading, Radio, IconButton, css, Text, Select, Button } from 'theme-ui'

import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import './styles.css'

const styles = {
  Parentdiv: {
    height: 30,
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 40,
    margin: 50
  },
  Childdiv: {
    height: '100%',
    width: `40%`,
    backgroundColor: 'red',
    borderRadius: 40,
    textAlign: 'right'
  },
  progresstext: {
    padding: 10,
    color: 'black',
    fontWeight: 900
  },


  range: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  // rangeSlider: {
  //   width: '100%',
  //   backgroundColor:'red'
  // }


}


const KtProgressEdit = ({ ...props }) => {

  const {
    karMarji,
    pgChange,
    setVal,
    iskontoMu
  } = props


  function onChangeListener(e) {
    setRange(e.target.value);
    pgChange(e.target.value);

  }

  var stil = {
    background: 'rgb(240,7,26)',
    background: 'linear-gradient(90deg, rgba(242,104,104,1) ' + (parseInt(karMarji) - 5) + '%, rgba(10,201,88,1) ' + (parseInt(karMarji) + 5) + '%)',
  }
if (iskontoMu==true) {
  stil = {
    background: 'rgb(10,201,88)',
    background: 'linear-gradient(90deg, rgba(10,201,88,1) 0%, rgba(215,111,120,1) ' + (parseInt(karMarji) + 10) + '%, rgba(240,7,26,1) 100%)',
  }
}
  const [range, setRange] = useState(0);
  const [step, setStep] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    try {
      if (setVal) {
        setRange(setVal);
        if (iskontoMu==true) {
        document.getElementsByClassName("itx-slider")[1].value=setVal;
          
        }else{
          document.getElementsByClassName("itx-slider")[0].value=setVal;
  
        }
      }
    } catch (error) {
      
    }
   
  }, [setVal])

 useEffect(() => {
    const rangeLinePadding = 14;
    const calcStep =
      (ref.current.offsetWidth - rangeLinePadding) / ref.current.max;
    setStep(calcStep);
  }, []);

  return (
    <Box sx={styles.range} className="slider" >
      <input  type="range" onChange={onChangeListener} ref={ref} style={stil} className='itx-slider' min="0" max="100" step="1" defaultValue="0" />
      <label
        htmlFor="range"
        style={{
          transform: `translateX(${range * step}px)`,
        }}>
        <span> {range} </span>
        {/* <svg viewBox="0 0 15 18">
            <path d="M14,7a7,7,0,0,1-2,4.87L7.72,16.35a1,1,0,0,1-1.44,0L2,11.87A6.93,6.93,0,0,1,0,7,7,7,0,0,1,14,7Z" />
          </svg> */}
      </label>
    </Box>
    // <Box sx={styles.Parentdiv}>
    //   <Box sx={styles.Childdiv}>
    //     <span sx={styles.progresstext}>{`${text}%`}</span>
    //   </Box>
    // </Box>
  )
}


export default WithDefaultContent(KtProgressEdit)


