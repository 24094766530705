import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Heading, Radio, IconButton, css, Text, Select, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import TeklifTextField from '@solid-ui-components/ContentForm/TeklifTextField'
// import FormInput3 from '@solid-ui-components/ContentForm/FormInput3'

import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpService from '../../../../../../site/src/service/httpService'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactModal from 'react-modal'
import { FaRegStar, FaPlus, FaHandHoldingHeart, FaHandHoldingMedical } from 'react-icons/fa'
import Slider from '@solid-ui-blocks/Slider/Block01'
import './styles.css'
import loadingGif from '../../../assets/model-cizim-kutu-tasarimi2.gif'
import parse from 'html-react-parser'
import { setKtTokenSifre, getKtLogin, logOut } from '../../../../../../site/src/service/auth'
import loadsGif from '../../../assets/loads.gif'
import loadsBeyazGif from '../../../assets/loadsBeyaz.gif'
import indirPng from '../../../assets/online-paskage-pdf-die-cut.png'
import favoriPng from '../../../assets/favori-bicak-izi-pdf.png'
import { ModalContext } from '@solid-ui-components/Modal'
// import indirmeCss from './_bicakizi.css' //style={indirmeCss}
import Tabs from '@solid-ui-components/Tabs'
// import { Tab } from 'react-tabs'
import { TabsContext } from '@solid-ui-components/Tabs'
import { AiOutlineCloseCircle, AiFillCloseSquare, AiOutlineDelete } from "react-icons/ai";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import Icon from '@solid-ui-components/ContentIcon'
import Reveal from '@solid-ui-components/Reveal'
import toast, { Toaster } from 'react-hot-toast'

const styles = {
  accordion: {
    '.accordion__button': {
      cursor: `pointer`,
      position: `relative`,
      outline: `none`,
      transition: `all 0.15s ease-in`
    },
    '.accordion__button:hover': {
      opacity: 0.75,
      '&::before': {
        borderColor: `beta`
      }
    },
    '.accordion__button::before': {
      content: `' '`,
      width: `10px`,
      height: `10px`,
      marginRight: `12px`,
      borderBottom: `3px solid currentColor`,
      borderLeft: `3px solid currentColor`,
      position: `absolute`,
      right: 4,
      top: `50%`,
      transform: `translate(0, -50%) rotate(45deg)`,
      transition: `all 0.15s ease-in`
    },
    '.accordion__button[aria-expanded="true"]::before, .accordion__button[aria-selected="true"]::before': {
      transform: `translate(0, -50%) rotate(-45deg)`,
      transition: `transform 0.35s ease-in`
    },
    '[hidden]': {
      display: `none`
    },
    '.accordion__panel': {
      animation: `fadeIn 0.25s ease-in`
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0.5,
        transform: 'translate(0, -15%)'
      },
      '100%': {
        opacity: 1,
        transform: 'translate(0, 0%)'
      }
    }
  }
  ,
  listItem: {
    alignItems: `stretch`,
    justifyContent: `flex-start`
  },
  line: {
    position: `absolute`,
    bottom: `10px`,
    left: `50%`,
    width: `1px`,
    height: t => `calc(100% - ${t.sizes.icon.md + 20}px)`,
    transform: `translateX(-50%)`,
    zIndex: 1,
    bg: `omega`
  },
  number: {
    color: `white`,
    fontWeight: `body`,
    borderRadius: `full`,
    bg: `alpha`,
    size: `icon.md`,
    textAlign: `center`,
    p: 2,
    mb: 0
  },


  modalContent2: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 4,
    px: 4
  },
  close: {
    position: `absolute`,
    size: [`icon.sm`, null, `icon.md`],
    zIndex: 99,
    width: 50,
    height: 50

  },

}

const MusteriEdit = ({ ...props }) => {

  const {

    addEventOpen,
    handlePopup,
    detayName,
    seciliRow

  } = props



  const formId = "frmMusteri"
  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const { setActiveModal } = useContext(ModalContext)
  const [ldg, setLdg] = useState(false);
  const [cizimLdg, setcizimLdg] = useState(false);
  //const [islemId, setIslemId] = useState(0);
  const [baslik, setBaslik] = useState("Yeni Müşteri");


  const [popupTip, setpopupTip] = useState(0);

  const [firtProcess, setfirtProcess] = useState("1");


  // async function GetTeklif() {


  //   const dt = {
  //     IndirmeId: IndirmeId,
  //     TeklifId: TeklifId,
  //   };

  //   httpServiceToken.post("Locked/GetTeklif", { params: dt }).then((res) => {
  //     // console.log(res);
  //     if (res.DogruMu) {

  //       if (res.Data) {
  //         if (window.location.search.includes("I=")) {
  //           window.location.assign("/ambalaj-kutu-maliyet-hesaplama?T=" + res.Data.Id)
  //         }
  //       }

  //       setTeklif(res.Data);

  //     } else {

  //     }
  //   }).catch((err) => {
  //     console.log(err);
  //   });


  // }

  const onSubmit = async e => {
    e.preventDefault();

    
  }


  return (

    <Box></Box>
    
    // <ReactModal
    //   id={"favoriteModal"}
    //   isOpen={addEventOpen}
    //   onRequestClose={() => setModalShow(false)}
    //   closeTimeoutMS={300}
    //   className='CustomModalPortalContent'
    //   overlayClassName='ModalPortalOverlay'
    //   shouldFocusAfterRender={false}
    //   sx={css({ ...styles.modalContent2 })}
    //   {...props}
    // >
    //   <form
    //     onSubmit={onSubmit}
    //     method='POST'
    //   >
       
    //       <Flex
    //         sx={{
    //           alignItems: `center`,
    //           flexDirection: [
    //             `column`,
    //             `row`
    //           ]
    //         }}
    //       >
    //        <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>
    //       <Box sx={{ textAlign: `center` }}>
    //         <ContentText content={{
    //           text: detayName,
    //           color: "omegaDarker",
    //           variant: "h5",
    //           space: 2
    //         }} />
    //         <Box sx={{ textAlign: `left` }}>
    //           <ContentText content={{
    //             text: "Hoş Geldiniz",
    //             color: "omegaDark",
    //           }} />
    //         </Box>
    //         <Box sx={{ textAlign: `left` }}>
    //           <ContentText content={{
    //             text: "Yalnız yıllık üyelikler için geçerlidir",
    //             color: "omegaDark",
    //           }} />
    //         </Box>
    //       </Box>        
    //       <Box>
    //         <Divider space={1} />
    //         <Box sx={{ textAlign: `left` }}>
    //           <ContentText content={{
    //             text: "> İndirme arşivinize sınırsız erişim hakkınız vardır",
    //             color: "omegaDark",
    //           }} />
    //         </Box>
    //         <Divider space={1} />
    //         <Box sx={{ textAlign: `left` }}>
    //           <ContentText content={{
    //             text: "> İndirilen çizimlerde revize yapabilirsiniz ",
    //             color: "omegaDark",
    //           }} />
    //         </Box>
    //         <Divider space={1} />
    //         <Box sx={{ textAlign: `left` }}>
    //           <ContentText content={{
    //             text: "> Çizimlerde gözden kaçabilen detaylara karşı mutlaka kontrol etmeniz önerilir",
    //             color: "omegaDark",
    //           }} />
    //         </Box>
    //         <Divider space={1} />
    //         <Box sx={{ textAlign: `left` }}>
    //           <FormInput3
    //             {...props}
    //             // onChange={onChanges}
    //             name={"txtIsinAdi"}
    //             id={"txtIsinAdi"}
    //             // value={formValues?.[formId]?.["txtIsinAdi"] || undefined}
    //             placeholder="İşin Adını Giriniz"
    //             minLength="3"
    //             required
    //           />
    //         </Box>
    //         <Divider space={2} />
    //         <Box sx={{ textAlign: `left` }}>
    //           <Text
    //             variant={"h5"}
    //             color="red"
    //             mb='1'
    //           >
    //             çiizim hata
    //           </Text>
    //         </Box>

    //       </Box>


    //       <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
    //         <div className="login-btn">


    //           <Button type="submit"
    //             aria-label="paket satın al"
    //             id={"btnKaydet"}
    //             onClick={e => {
    //               window.location.assign("/ambalaj-web/lisans-paketleri")
    //             }}
    //           >
    //             Lisans Satın Al
    //           </Button>

    //           <Button type="submit"
    //             id={"btnKaydet"}
    //             aria-label="pdf çizim indir"
    //             onClick={e => setbtnID(e.target.id)}
    //           >
    //             Pdf Olarak İndir
    //           </Button>

    //         </div>
    //       </Box>
    //     </Box>
    //       </Flex>
        
    //   </form>
    // </ReactModal>


  )
}


export default WithDefaultContent(MusteriEdit)


