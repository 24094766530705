import React, { useContext, useState } from 'react'
import { Container, Flex, Box, css, Button } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import QuickSignupForm from '@solid-ui-components/QuickSignupForm'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { getPricing, setPrice, getKtLogin } from '../../../../../../site/src/service/auth'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import { ModalContext } from '@solid-ui-components/Modal'


const HeroBlock01 = ({
  content: { text = [], images, buttons, form },
  reverse
}) => {

  const { setActiveModal } = useContext(ModalContext)
  const [ucretsizClick, setucretsizClick] = useState(0);
  const [islemMsj, setislemMsj] = useState("ÜCRETSİZ DENEYİN")


  return (
    <Container>
      <Flex
        sx={{
          alignItems: [null, null, null, `center`],
          flexDirection: [
            reverse ? `column-reverse` : `column`,
            null,
            null,
            reverse ? `row-reverse` : `row`
          ]
        }}
      >
        <Box
          sx={{
            flexBasis: [null, null, null, `3/5`],
            [reverse ? 'ml' : 'mr']: [null, null, null, 5],
            position: `relative`,
            textAlign: `center`
          }}
        >
          <ContentImages
            content={{ images }}
            loading='eager'
            reverse={reverse}
            imagePosition='center'
          />
        </Box>
        <Box
          sx={{
            flexBasis: `2/5`,
            textAlign: [`center`, null, null, `left`]
          }}
        >
          <Reveal effect='fadeInDown'>
            <ContentText content={text} />
          </Reveal>
          {buttons && (
            <Reveal
              effect='fadeInRight'
              delay={1}
              css={css({ mb: [4, null, null, 0] })}
            >
              {buttons && (
                <>
                  <Divider space={3} />
                  <Button
                    type="submit"
                    aria-label="login kayıt ol"
                    id={"btnKayitOl"} index={3}
                    variant="white"
                    onClick={async e => {
                      e.preventDefault();
                      localStorage.setItem("723327ac7dc90a0020f891aC", "9");
                      localStorage.setItem("623327ac7dc90a0020f891aa", "4");
                      setActiveModal('authentication');



                      // localStorage.setItem("f", "h");
                      // const secilenKredi = KrediListesi.find(s => s.SeciliMi == true);
                      // setPrice(secilenKredi.Id, secilenKredi.PaketGunSuresi, secilenKredi.Tutar, secilenKredi.IndirmeHakki, Baslik);

                      // const ktLoginMiii = await getKtLogin();
                      // if (ktLoginMiii == true) {

                      //   setucretsizClick(ucretsizClick + 1);
                      //   if (ucretsizClick == 1) {
                      //     //console.log(ucretsizClick);
                      //     httpServiceToken.post("Locked/UcretsizBirAyEkle", { params: null }).then((res) => {
                      //       if (res.DogruMu) {

                      //       } else {

                      //       }

                      //      setislemMsj(res.Mesaj);
                      //     }).catch((err) => {

                      //     });
                      //   }else 
                      //   {
                      //     //window.location.assign("/ambalaj-web/lisans-paketleri");
                      //   }

                      // }
                      // else {
                      //   localStorage.setItem("723327ac7dc90a0020f891aC", "9");
                      //   localStorage.setItem("623327ac7dc90a0020f891aa", "5");
                      //   setActiveModal('authentication');
                      // }

                    }}
                  >

                    Ücretsiz Üye Ol
                  </Button>
                  {/* <ContentButtons content={buttons} /> */}
                </>
              )}
            </Reveal>
          )}
          {form && (
            <Reveal
              effect='fadeInRight'
              delay={1}
              css={css({ mb: [4, null, null, 0] })}
            >
              <QuickSignupForm {...form} space={3} />
            </Reveal>
          )}
        </Box>
      </Flex>
    </Container>
  )
}

export default WithDefaultContent(HeroBlock01)
