import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Tabs from '@solid-ui-components/Tabs'
import Hero from '@solid-ui-blocks/Hero/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Features from '@solid-ui-blocks/Features/Block02'
import Screenshot from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Process from '@solid-ui-blocks/Features/Block03'
import Testimonials from '@solid-ui-blocks/Testimonials/Block03'
import Fiyat from '@solid-ui-blocks/Pricing/Fiyat'
import Stats from '@solid-ui-blocks/Stats/Block01'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import httpService from '../service/httpService'
import './_kt_styles.css'
import { getCok, setCok, getKtLogin, logOut } from '../service/auth'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'




const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)



  useEffect(async () => {
    try {
      const gtCok = getCok();
      if (gtCok == null) {
        const hd = "noth";
        fetch('https://ipapi.co/json/')
          .then(function (response) {
            response.text().then(txt => {
              const dd = JSON.parse(txt);
              httpService.post("UnLocked/CokSet", { params: dd }).then((res) => {
                if (res.DogruMu) {
                  setCok(res.UserKey);
                } else {

                }
              }).catch((err) => {

              });

            });
          })
          .catch(function (error) {

          });

      }
    } catch (error) {

    }

    if (typeof window !== 'undefined') {
      const ktLoginMiii = await getKtLogin();
      if (ktLoginMiii == true) {
      } else {
        logOut();
      }
    }
  }, [])

 


  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {

      const isScrolled = window.scrollY > 5;
      if (isScrolled !== scrolled) {
        console.log(scrolled);
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <KtLayout prp={props} cnt={content} lightmi={!scrolled} >
      <Seo title='Ambalaj Bıçak İzi Modelleri ' meta="" />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      {/* hero sonu*/}
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <div id="cozumler">
        <Container variant='wide' sx={styles.tabsContainer}>
          <Tabs space={5}>
            <FeatureOne content={content['tab-feature-one']} />
            <FeatureTwo content={content['tab-feature-two']} />
            <FeatureThree content={content['tab-feature-three']} />
          </Tabs>
        </Container>
      </div>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={3} variant='dots' position='bottom' arrows>
          <Screenshot content={content['screenshot-one']} />
          <Screenshot content={content['screenshot-two']} />
          <Screenshot content={content['screenshot-three']} />
          <Screenshot content={content['screenshot-zero']} />
        </Tabs>
      </Container>
      {/* kicker */}
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <div id="hakkimizda">
        <Process content={content['process']} /></div>
      {/* 1234 yukarıdan aşağı canlı destek btn sonu */}
      <Divider space='5' />
      <Container variant='full' sx={styles.featuresContainer}>
        <Features content={content['features']} />
      </Container>
      {/* 4 kart daha fazla btn */}
      <Divider space='5' />
      <Container variant='full' sx={styles.socialProofContainer}>
        <Divider space='5' />
        <Divider space='5' />
        <Container variant='narrow'>
          <Stats content={content['stats']} />
          {/* sayılar  */}
          <Divider space='5' />
        </Container>
        <Testimonials content={content['testimonials']} />
        {/* 99 lu ekran  */}
        <Divider space='5' />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      {/* <Fiyat content={paketListesi} /> */}
      <Container variant='narrow'>
        <Faq content={content['faq']} />
        {/* akordion */}
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Blog content={content['latest-blogs']} />
      {/* makaleler blog */}
      <Divider space='5' />
      <CanliDestek></CanliDestek>     
    </KtLayout>
  )
}

export const query = graphql`
  query homepageBlockContent {
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage